import { countries } from 'countries-list';
import z from 'zod';

// Shared enums and types to work with Climatiq APIs

export const YearSchema = z.number().int().gt(1970).lt(2150);
export type Year = z.infer<typeof YearSchema>;

export enum ActivityId {
  // Whitelist of known activity IDs we leverage in Niklas
  // Use the Data Explorer ( https://www.climatiq.io/data ) to find activity IDs
  // For spend-based we use only EXIOBASE activities
  ITServices = 'professional_services-type_computer_related_services',
  ITHardware = 'office_equipment-type_office_machinery_computers',
  ResearchDevelopment = 'professional_services-type_research_development_services',
  RealEstateServices = 'real_estate-type_real_estate_services',
  HotelAndRestaurants = 'restaurants_accommodation-type_hotel_restaurant_services',
  EducationServices = 'education-type_education_services',
  PostTelecommunication = 'communication_services-type_post_telecommunication',
  ConstructionWork = 'construction-type_construction_work',
  ElectricalEquipment = 'electrical_equipment-type_electrical_machinery_apparatus_not_elsewhere_specified',
  InsuranceAndPension = 'insurance-type_insurance_pension_funding_services_except_compulsory_social_security_services',
  PaperAndPrint = 'paper_products-type_printed_matter_recorded_media',
  MarketingAndOtherBusinessServices = 'professional_services-type_other_business_services',
  Beverages = 'consumer_goods-type_beverages',
  FoodProducts = 'consumer_goods-type_food_products_not_elsewhere_specified',
  FurnitureOtherGoods = 'consumer_goods-type_furniture_other_manufactured_goods_not_elsewhere_specified',
  FinancialServices = 'financial_services-type_financial_intermediation_services_except_insurance_pension_funding_services',
  MachineryEquipment = 'machinery-type_machinery_equipment_not_elsewhere_specified',
  PaperProducts = 'paper_products-type_paper_paper_products',
  PassengerVehicles = 'passenger_vehicle-vehicle_type_motor_vehicles_trailers_semitrailers-fuel_source_na-engine_size_na-vehicle_age_na-vehicle_weight_na',
  TransportSupportServices = 'transport_services-type_supporting_auxiliary_and_travel_agency_services',
  VehicleSaleMaintenanceRepair = 'vehicle_manufacture-type_sale_maintenance_repair_parts_accessories_motor_vehicles_motorcycles',
  AuxiliaryFinancialServices = 'financial_services-type_services_auxiliary_to_financial_intermediation',
  WholesaleTradeServices = 'wholesale_trade-type_wholesale_trade_commission_trade_services_except_of_motor_vehicles_motorcycles',
  RecreationalCulturalSportingServices = 'consumer_goods-type_recreational_cultural_sporting_services',
  EquipmentRental = 'equipment_rental-type_rental_of_machinery_equipment_without_operator_of_personal_household_goods',
  CleaningServices = 'domestic_services-type_cleaning',
  OtherServices = 'organizational_activity-type_other_services_not_elsewhere_specified',
}
export const ActivityIdSchema = z.nativeEnum(ActivityId);
export const ActivityKeySchema = z.union(
  Object.keys(ActivityId).map((key: string) => z.literal(key)) as [
    z.ZodLiteral<string>,
    z.ZodLiteral<string>,
    //... in reality more than 2 keys, but this tells the typescript compiler that the array has at least 2.
  ],
);
export type ActivityKey = keyof typeof ActivityId;

// This is important to put activities in the correct GHG Protocol scope category
export enum ActivityKind {
  PurchasedServices = 'services',
  PurchasedGoods = 'goods',
}
export const ActivityKindByKey: Record<ActivityKey, ActivityKind> = {
  ITServices: ActivityKind.PurchasedServices,
  ITHardware: ActivityKind.PurchasedGoods,
  ResearchDevelopment: ActivityKind.PurchasedServices,
  RealEstateServices: ActivityKind.PurchasedServices,
  HotelAndRestaurants: ActivityKind.PurchasedServices,
  EducationServices: ActivityKind.PurchasedServices,
  PostTelecommunication: ActivityKind.PurchasedServices,
  ConstructionWork: ActivityKind.PurchasedServices,
  ElectricalEquipment: ActivityKind.PurchasedGoods,
  InsuranceAndPension: ActivityKind.PurchasedServices,
  PaperAndPrint: ActivityKind.PurchasedGoods,
  MarketingAndOtherBusinessServices: ActivityKind.PurchasedServices,
  Beverages: ActivityKind.PurchasedGoods,
  FoodProducts: ActivityKind.PurchasedGoods,
  FurnitureOtherGoods: ActivityKind.PurchasedGoods,
  FinancialServices: ActivityKind.PurchasedServices,
  MachineryEquipment: ActivityKind.PurchasedGoods,
  PaperProducts: ActivityKind.PurchasedGoods,
  PassengerVehicles: ActivityKind.PurchasedGoods,
  TransportSupportServices: ActivityKind.PurchasedServices,
  VehicleSaleMaintenanceRepair: ActivityKind.PurchasedServices,
  AuxiliaryFinancialServices: ActivityKind.PurchasedServices,
  WholesaleTradeServices: ActivityKind.PurchasedServices,
  RecreationalCulturalSportingServices: ActivityKind.PurchasedServices,
  EquipmentRental: ActivityKind.PurchasedServices,
  CleaningServices: ActivityKind.PurchasedServices,
  OtherServices: ActivityKind.PurchasedServices,
};

export const TransportModeSchema = z.enum(['road', 'rail', 'sea', 'air']);
export type TransportMode = z.infer<typeof TransportModeSchema>;

export const TravelModeSchema = z.enum(['air', 'car', 'rail']);
export type TravelMode = z.infer<typeof TravelModeSchema>;

export const CountryCodeSchema = z.union(
  Object.keys(countries).map((countryCode) => z.literal(countryCode)) as [
    z.ZodLiteral<string>,
    z.ZodLiteral<string>,
    //... in reality way more than 2 country codes, but this tells the typescript compiler that the array has at least 2.
  ],
);
export type CountryCode = z.infer<typeof CountryCodeSchema>;

export const QueryLocationSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/travel#query-location
  query: z.string(),
  country: CountryCodeSchema.optional(),
});
export type QueryLocation = z.infer<typeof QueryLocationSchema>;

export const ResponseLocationSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/travel#response-location
  name: z.string(),
  confidence_score: z.number().gt(0).lte(1).nullable(),
});
export type ResponseLocation = z.infer<typeof ResponseLocationSchema>;

export const UnitTypes = {
  // Source: https://www.climatiq.io/docs/api-reference/models/parameters#available-unit-types
  // Add more as needed!

  EnergySchema: z.object({
    energy: z.number().positive(),
    energy_unit: z.enum([
      'Wh',
      'kWh',
      'MWh',
      'MJ',
      'GJ',
      'TJ',
      'therm',
      'MMBTU',
    ]),
  }),

  MoneySchema: z.object({
    money: z.number().positive(),
    money_unit: z.enum([
      'afn',
      'dzd',
      'ars',
      'aud',
      'bhd',
      'brl',
      'cad',
      'kyd',
      'cny',
      'dkk',
      'egp',
      'eur',
      'hkd',
      'huf',
      'isk',
      'inr',
      'iqd',
      'ils',
      'jpy',
      'lbp',
      'mxn',
      'mad',
      'nzd',
      'nok',
      'qar',
      'rub',
      'sar',
      'sgd',
      'zar',
      'krw',
      'sek',
      'chf',
      'twd',
      'thb',
      'tnd',
      'try',
      'aed',
      'gbp',
      'usd',
    ]),
  }),

  WeightSchema: z.object({
    weight: z.number().positive(),
    weight_unit: z.enum([
      'g',
      'kg',
      't', // metric ton
      'lb',
      'ton', // US short ton
    ]),
  }),
};

export const UsedEmissionFactorSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/audit-trail#used-emission-factor
  id: z.string(),
  name: z.string(),
  activity_id: ActivityIdSchema,
  access_type: z.enum(['private', 'public']),
  source: z.string(),
  source_dataset: z.string(),
  year: z.number(),
  region: z.string(),
  category: z.string(),
  source_lca_activity: z.string(),
  data_quality_flags: z.array(z.string()),
});
export type UsedEmissionFactor = z.infer<typeof UsedEmissionFactorSchema>;

export const ConstituentGasesSchema = z.object({
  // source: https://www.climatiq.io/docs/api-reference/audit-trail#constituent-gases
  co2e_total: z.number().nullable(),
  co2e_other: z.number().nullable(),
  co2: z.number().nullable(),
  ch4: z.number().nullable(),
  n2o: z.number().nullable(),
});
export type ConstituentGases = z.infer<typeof ConstituentGasesSchema>;

export const EstimationSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/models/estimation
  co2e: z.number(),
  co2e_unit: z.enum(['kg']), // possibly others, like tons? unclear in documentation
  co2e_calculation_method: z.enum(['ar4', 'ar5', 'ar6']),
  co2e_calculation_origin: z.enum(['source', 'climatiq']),
  emission_factor: UsedEmissionFactorSchema.nullable(),
  constituent_gases: ConstituentGasesSchema.nullable(),
  activity_data: z.object({
    activity_value: z.number(),
    activity_unit: z.string(),
  }),
  audit_trail: z.enum(['enabled', 'disabled']),
});
export type Estimation = z.infer<typeof EstimationSchema>;

export const SourceTrailSchema = z.array(
  z.object({
    name: z.string().nullable(),
    source: z.string().nullable(),
    source_dataset: z.string().nullable(),
    year: z.coerce.number().nullable(),
    region: z.string().nullable(),
    region_name: z.string().nullable(),
    data_category: z.string().nullable(),
  }),
);
export type SourceTrail = z.infer<typeof SourceTrailSchema>;

export const ProcurementRequestSchema = z
  .object({
    // Source: https://www.climatiq.io/docs/api-reference/procurement#request
    activity: z.object({
      activity_id: ActivityIdSchema,
    }),
    spend_year: YearSchema,
    spend_region: z.string(),
  })
  .merge(UnitTypes.MoneySchema);
export type ProcurementRequest = z.infer<typeof ProcurementRequestSchema>;

export const ProcurementResponseSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/procurement#response
  estimate: EstimationSchema,
  calculation_details: z
    .object({
      tax_margin: z.number().nullable(),
      trade_margin: z.number(),
      transport_margin: z.number(),
      inflation_applied: z.number(),
    })
    .nullable(),
  source_trail: SourceTrailSchema,
});
export type ProcurementResponse = z.infer<typeof ProcurementResponseSchema>;

export const FreightRequestSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/intermodal-freight#request
  cargo: UnitTypes.WeightSchema,
  route: z.array(
    z.union([
      z.object({
        location: QueryLocationSchema,
      }),
      z.object({
        transport_mode: TransportModeSchema,
      }),
    ]),
  ),
});
export type FreightRequest = z.infer<typeof FreightRequestSchema>;

export const FreightResponseSchema = z.object({
  co2e: z.number().positive(),
  co2e_unit: z.enum(['kg']),
  distance_km: z.number().positive(),
  route: z.array(
    z.union([
      z
        .object({
          type: z.enum(['location']),
        })
        .merge(ResponseLocationSchema),
      z.object({
        type: z.enum(['leg']),
        co2e: z.number().positive(),
        co2e_unit: z.enum(['kg']),
        transport_mode: TransportModeSchema,
        distance_km: z.number().positive(),
        estimates: z.array(EstimationSchema),
        source_trail: SourceTrailSchema,
      }),
    ]),
  ),
});
export type FreightResponse = z.infer<typeof FreightResponseSchema>;

export const TravelRequestSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/travel#distance-based-method
  travel_mode: TravelModeSchema,
  origin: QueryLocationSchema,
  destination: QueryLocationSchema,
  distance_km: z.number().positive().optional(), // can be used instead of destination
  year: YearSchema,
});
export type TravelRequest = z.infer<typeof TravelRequestSchema>;

export const TravelResponseSchema = z.object({
  co2e: z.number().positive(),
  co2e_unit: z.enum(['kg']),
  distance_km: z.number().positive(),
  origin: ResponseLocationSchema,
  destination: ResponseLocationSchema,
  direct_emissions: z.object({
    source_trail: SourceTrailSchema,
  }),
  indirect_emissions: z.object({
    source_trail: SourceTrailSchema,
  }),
});
export type TravelResponse = z.infer<typeof TravelResponseSchema>;

export const HotelStayRequestSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/travel#hotels
  hotel_nights: z.number().int().positive(),
  location: QueryLocationSchema,
  year: YearSchema,
});
export type HotelStayRequest = z.infer<typeof HotelStayRequestSchema>;

export const HotelStayResponseSchema = z.object({
  // Source: https://www.climatiq.io/docs/api-reference/travel#response-2
  co2e: z.number().positive(),
  co2e_unit: z.enum(['kg']),
  location: ResponseLocationSchema,
  source_trail: SourceTrailSchema,
});
export type HotelStayResponse = z.infer<typeof HotelStayResponseSchema>;
