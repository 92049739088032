import type { LocalizedWord } from '../i18n/locales/localized-words/localized-words';
import type { Language } from '../i18n/types';

const VOWELS = ['a', 'e', 'i', 'o', 'u', 'y'];

/**
 * Returns a string representing the percentage difference
 * between a reference value and another value.
 *
 * For example, returns '-30%' for (100, 70) and '1%' for (100, 101).
 *
 * TECHNICAL-DEBT: not adjusted to DE, NL, using the same as EN
 */
export function buildPercentageDifferenceFigure(
  referenceValue: number,
  value: number,
): string {
  const difference = percentageDifference(referenceValue, value);
  if (difference < 0) return `${difference}%`;
  if (difference > 0) return `+${difference}%`;
  return '0%';
}

/**
 * Returns a text representing the percentage difference
 * between 2 values.
 *
 * For example, returns '30% less than' for (100, 70).
 */
export function buildPercentageDifferenceText(
  referenceValue: number,
  value: number,
  lang: Language,
) {
  const diffPercentage = percentageDifference(referenceValue, value);
  switch (lang) {
    case 'de':
      if (diffPercentage < 0) {
        return `${Math.abs(diffPercentage).toFixed(0)}% weniger`;
      }
      if (diffPercentage > 0) {
        return `${diffPercentage.toFixed(0)}% mehr`;
      }
      return 'ungefähr gleich wie';
    case 'en':
      if (diffPercentage < 0) {
        return `${Math.abs(diffPercentage).toFixed(0)}% less than`;
      }
      if (diffPercentage > 0) {
        return `${diffPercentage.toFixed(0)}% more than`;
      }
      return 'about the same as';
    case 'fr':
      if (diffPercentage < 0) {
        return `${Math.abs(diffPercentage).toFixed(0)}% de moins que`;
      }
      if (diffPercentage > 0) {
        return `${diffPercentage.toFixed(0)}% de plus que`;
      }
      return 'similaire à';
    case 'nl':
      if (diffPercentage < 0) {
        return `${Math.abs(diffPercentage).toFixed(0)}% minder dan`;
      }
      if (diffPercentage > 0) {
        return `${diffPercentage.toFixed(0)}% meer dan`;
      }
      return 'ongeveer hetzelfde als';
    default:
      throw new Error(`Unsupported language: ${String(lang)}`);
  }
}

/**
 * Returns a new string where each word has the first letter
 * in uppercase. Other characters are left unchanged.
 *
 * Similar to `lodash.upperFirst()` for all words.
 *
 * @param string
 */
export function capitalizeWords(string: string): string {
  return string
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

/**
 * Generates a conjonction sentence from the specified array of words.
 *
 * Examples:
 *   - ['a', 'b', 'c'], 'and' => 'a, b and c'
 *   - ['a', 'b'], 'et' => 'a et b'
 *
 * @param words
 * @param conjonctionWord
 * @returns
 */
export function makeConjunctionSentence(
  words: string[],
  conjonctionWord: string,
): string {
  if (words.length === 0) return '';
  if (words.length === 1) return words[0];
  return `${words.slice(0, -1).join(', ')} ${conjonctionWord} ${words[words.length - 1]}`;
}

/**
 * For a language with articles, returns the word preceded by the
 * adequate undefined article.
 *
 * - In English: returns the word (no article).
 * - In French:
 *   - "polyester" => "du polyester"
 *   - "laine" => "de la laine"
 *   - "acrylique" => "de l'acrylique"
 *
 * @param wordWithGenre : WordWithGenre
 * @param definedOrUndefined : 'defined' or 'undefined', specifies the type
 *   of article to use
 * @param lang : Language
 */
export function makeWordWithArticle(
  wordWithGenre: LocalizedWord,
  definedOrUndefined: 'defined' | 'undefined',
  lang: Language,
): string {
  switch (lang) {
    case 'de':
    case 'en':
    case 'nl':
      return wordWithGenre.word;
    case 'fr':
      if (VOWELS.includes(wordWithGenre.word[0].toLowerCase())) {
        if (definedOrUndefined === 'defined') return `l'${wordWithGenre.word}`;
        return `de l'${wordWithGenre.word}`;
      }
      if (wordWithGenre.genre === 'male') {
        if (definedOrUndefined === 'defined') return `le ${wordWithGenre.word}`;
        return `du ${wordWithGenre.word}`;
      }
      if (wordWithGenre.genre === 'female') {
        if (definedOrUndefined === 'defined') return `la ${wordWithGenre.word}`;
        return `de la ${wordWithGenre.word}`;
      }
      if (wordWithGenre.genre === 'plural') {
        if (definedOrUndefined === 'defined')
          return `les ${wordWithGenre.word}`;
        return `des ${wordWithGenre.word}`;
      }
      return wordWithGenre.word;
  }
}

/**
 * Returns the specified word preceded with the 'in' adverbial pronoun.
 *
 * - In English, e.g. "in France".
 * - In French, depends on the word's genre, e.g. "en France", "au Portugal".
 *
 * TECHNICAL-DEBT: not adjusted to DE, NL, using the same as EN
 */
export function makeWordWithInAdverbialPronoun(
  wordWithGenre: LocalizedWord,
  lang: Language,
): string {
  switch (lang) {
    case 'de':
    case 'en':
    case 'nl':
      return `in ${wordWithGenre.word}`;
    case 'fr':
      if (wordWithGenre.genre === 'female') return `en ${wordWithGenre.word}`;
      if (wordWithGenre.genre === 'male') return `au ${wordWithGenre.word}`;
      if (wordWithGenre.genre === 'plural') return `aux ${wordWithGenre.word}`;
      return wordWithGenre.word;
  }
}

export function convertToUpperSnakeCase(camelCaseStr: string) {
  return camelCaseStr
    .replace(/([a-z])([A-Z])/g, '$1_$2') // Add underscore between lowercase and uppercase letters
    .toUpperCase(); // Convert the entire string to uppercase
}
function percentageDifference(referenceValue: number, value: number): number {
  return Math.round(((value - referenceValue) / referenceValue) * 100);
}
