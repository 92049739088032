import classNames from 'classnames';

type TitleProps = {
  title: string;
  className?: string;
  subTitle?: string;
  align?: 'left' | 'center' | 'right';
};

const Title = ({
  className = '',
  title,
  subTitle,
  align = 'left',
}: TitleProps) => {
  return (
    <div className={classNames(className, 'flex flex-col w-full')}>
      <h1
        className={classNames(
          'text-carbon-800 text-[28px] font-bold leading-9',
          align === 'center' && 'text-center',
          align === 'right' && 'text-right',
        )}
      >
        {title}
      </h1>
      {subTitle && (
        <div
          className={classNames(
            'font-medium text-carbon-500 text-base leading-normal',
            align === 'center' && 'text-center',
            align === 'right' && 'text-right',
          )}
        >
          {subTitle}
        </div>
      )}
    </div>
  );
};
export default Title;
