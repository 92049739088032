import type z from 'zod';
import * as Climatiq from './types';

const CLIMATIQ_API_URL = 'api.climatiq.io';

export class ClimatiqApi {
  private apiKey: string;

  constructor(apiKey?: string) {
    const newApiKey = apiKey || process.env.CLIMATIQ_API_KEY;

    if (!newApiKey) {
      throw new Error('CLIMATIQ_API_KEY is not set');
    }

    this.apiKey = newApiKey;
  }

  // Note: Procurement API only works with Exiobase activities
  public async getProcurementSpendImpact(
    request: Climatiq.ProcurementRequest,
  ): Promise<Climatiq.ProcurementResponse> {
    return this.fetchClimatiqApi({
      request: {
        endpoint: '/procurement/v1/spend',
        schema: Climatiq.ProcurementRequestSchema,
        data: request,
      },
      response: {
        schema: Climatiq.ProcurementResponseSchema,
      },
    });
  }

  public async getFreightImpact(
    request: Climatiq.FreightRequest,
  ): Promise<Climatiq.FreightResponse> {
    return this.fetchClimatiqApi({
      request: {
        endpoint: '/freight/v1/intermodal',
        schema: Climatiq.FreightRequestSchema,
        data: request,
      },
      response: {
        schema: Climatiq.FreightResponseSchema,
      },
    });
  }

  public async getTravelImpact(
    request: Climatiq.TravelRequest,
  ): Promise<Climatiq.TravelResponse> {
    return this.fetchClimatiqApi({
      request: {
        previewEndpoint: true,
        endpoint: '/travel/v1-preview1/distance',
        schema: Climatiq.TravelRequestSchema,
        data: request,
      },
      response: {
        schema: Climatiq.TravelResponseSchema,
      },
    });
  }

  public async getHotelStayImpact(
    request: Climatiq.HotelStayRequest,
  ): Promise<Climatiq.HotelStayResponse> {
    return this.fetchClimatiqApi({
      request: {
        previewEndpoint: true,
        endpoint: '/travel/v1-preview1/hotel',
        schema: Climatiq.HotelStayRequestSchema,
        data: request,
      },
      response: {
        schema: Climatiq.HotelStayResponseSchema,
      },
    });
  }

  private async fetchClimatiqApi<
    RequestSchema extends z.AnyZodObject,
    ResponseSchema extends z.AnyZodObject,
  >({
    request,
    response,
  }: {
    request: {
      previewEndpoint?: boolean;
      endpoint: string;
      schema: RequestSchema;
      data: z.infer<RequestSchema>;
    };
    response: {
      schema: ResponseSchema;
    };
  }): Promise<z.infer<ResponseSchema>> {
    try {
      request.schema.parse(request.data);
    } catch (error) {
      throw new Error(
        `Could not match Climatiq API request against schema: ${JSON.stringify(request.data)}, ${(error as { message: string })?.message}`,
      );
    }

    const apiRes = await fetch(
      `https://${request.previewEndpoint ? 'preview.' : ''}${CLIMATIQ_API_URL}${request.endpoint}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.apiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request.data),
      },
    );

    if (!apiRes.ok) {
      throw new Error(
        `Failed to fetch Climatiq API ${request.endpoint}: ${apiRes.status} ${apiRes.statusText}, ${await apiRes.text()}`,
      );
    }

    const resDataJson = await apiRes.json();

    try {
      const resDataValidated = await response.schema.parseAsync(resDataJson);

      return resDataValidated;
    } catch (error) {
      throw new Error(
        `Failed to validate Climatiq API response: ${(error as { message: string })?.message}, ${JSON.stringify(resDataJson)}`,
      );
    }
  }
}
