const APP_NAME = 'cli';
const NO_COLOR = process.env.NO_COLOR === 'true';
const ENV_IS_DEV = process.env.NODE_ENV === 'development';

type LoggerContext = 'PROGRESS' | 'EFS' | 'ANY';

/**
 *
 * @param message
 * @param style
 * @param isVerbose set to `true` if the log must only be displayed in verbose mode
 */
export function log(
  message: string,
  style: 'normal' | 'warning' | 'error' | 'info' | 'success' = 'normal',
  isVerbose = false,
  options?: { context: LoggerContext },
) {
  if (!shouldLog({ messageContext: options?.context ?? 'ANY', isVerbose })) {
    return;
  }
  const scriptName = APP_NAME;
  const color = consoleStyles[style];
  const time = new Date().toISOString();
  const prefix = ENV_IS_DEV ? '' : `[${time}] [${scriptName}] `;
  const text = `${prefix}${message}`;
  if (!color || NO_COLOR) {
    let styleSuffix = '';
    if (style === 'warning') {
      styleSuffix = ' [WARN] ';
    } else if (style === 'error') {
      styleSuffix = ' [ERR] ';
    }
    console.log(`${text}${styleSuffix}`);
  } else {
    console.log(`${color}%s\x1b[0m`, `${text}`);
  }
}

/**
 * Determines if we should log:
 * - If `verbose=false`, yes.
 * - Otherwise:
 *   - If `LOG_VERBOSE=(true|all)` then we log for all contexts.
 *   - If `LOG_VERBOSE=context1,context2` then we log for those contexts only.
 */
function shouldLog({
  messageContext,
  isVerbose,
}: {
  messageContext: LoggerContext;
  isVerbose: boolean;
}): boolean {
  if (!isVerbose) {
    return true;
  }

  const logVerboseEnvVar = process.env.LOG_VERBOSE;
  if (!logVerboseEnvVar) {
    return false;
  }

  const contextsWithVerboseLoggingEnabled =
    logVerboseEnvVar === 'true' ? ['any'] : logVerboseEnvVar.split(',');
  if (contextsWithVerboseLoggingEnabled.includes('any')) {
    // Logging verbose messages for any context
    return true;
  }

  return contextsWithVerboseLoggingEnabled.includes(messageContext);
}

// From https://simplernerd.com/js-console-colors/
const consoleColors = {
  reset: '\x1b[0m',
  bright: '\x1b[1m',
  dim: '\x1b[2m',
  underscore: '\x1b[4m',
  blink: '\x1b[5m',
  reverse: '\x1b[7m',
  hidden: '\x1b[8m',
  // Foreground (text) colors
  fg: {
    black: '\x1b[30m',
    red: '\x1b[31m',
    green: '\x1b[32m',
    yellow: '\x1b[33m',
    blue: '\x1b[34m',
    magenta: '\x1b[35m',
    cyan: '\x1b[36m',
    white: '\x1b[37m',
    crimson: '\x1b[38m',
  },
  // Background colors
  bg: {
    black: '\x1b[40m',
    red: '\x1b[41m',
    green: '\x1b[42m',
    yellow: '\x1b[43m',
    blue: '\x1b[44m',
    magenta: '\x1b[45m',
    cyan: '\x1b[46m',
    white: '\x1b[47m',
    crimson: '\x1b[48m',
  },
};

const consoleStyles = {
  normal: null,
  warning: consoleColors.fg.yellow,
  error: consoleColors.fg.red,
  info: null,
  success: consoleColors.fg.green,
};
